import * as React from 'react';
import {  Col, Row } from "react-bootstrap";
import EditUser from '../../components/admin/EditUser';
import UnapprovedUserList from '../../components/admin/UnapprovedUserList';

export default function MemberManagement() {
  return (
    <Row>
      <Col lg>
        <EditUser />
      </Col>
      <Col lg>
        <UnapprovedUserList showBinnedUsers={true} />
      </Col>
    </Row>
  )
}
