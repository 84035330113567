import * as React from 'react';
import { useState, ReactNode } from 'react';
import { Alert, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from "../imgs/SUGC_white_on_blue.webp";
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import useWindowSize from "../utils/useWindowSize"

export interface NavBarProps {
  children?: ReactNode
}

export default function NavBar( {children} : NavBarProps ) {
  const { currentUser, logout, currentUserInfo } = useAuth();
  const [error, setError] = useState("");
  const [expanded, setExpanded] = useState<boolean>(false)
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  async function handleLogout() {
    setError("")
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
      console.log(error);
    }
  }

  return (
    <>
      <Navbar
        fixed="top"
        onPointerLeave={() => setExpanded(false)}
        className='bg-sugc-blue'
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        style={{ marginBottom: "50px" }}
      >
        <Container>
          <Navbar.Brand href="/">
            <div style={{ height: "59px", width: "94px", display: "flex", justifyContent: "center", alignItems:"center", overflow:"clip" }}>
            <img style={{ width: "100%" }} src={logo} alt="logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle 
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse role="navigation" id="responsive-navbar-nav">
            <Nav  className='me-auto' />
            <Nav>
              <NavDropdown show={windowSize.width < 992 ? true : undefined } title="About SUGC">
                <NavDropdown.Item 
                  to="/about"
                  as={Link}
                  onClick={() => setExpanded(false)}>
                  About the Club
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/typical-day"
                  as={Link}
                  onClick={() => setExpanded(false)}>
                  A Typical day
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/joining"
                  as={Link}
                  onClick={() => setExpanded(false)}>
                  Joining
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/faq"
                  as={Link}
                  onClick={() => setExpanded(false)}>
                  FAQ
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/pricing"
                  as={Link}
                  onClick={() => setExpanded(false)}>
                  Pricing
                </NavDropdown.Item>
              </NavDropdown>
              <Link className="nav-link" onClick={() => setExpanded(false)} to="what-is-gliding">
                What is gliding?
              </Link>

              {currentUserInfo && currentUserInfo.isAdmin && <Link className="nav-link" onClick={() => setExpanded(false)} to="/admin">
                Admin
              </Link>}

              {currentUser && <>
                <Link className="nav-link" onClick={() => setExpanded(false)} to="/dashboard">
                  Dashboard
                </Link>
                <Link className="nav-link" onClick={() => setExpanded(false)} to="/update-profile">
                  Profile
                </Link>
                <Nav.Link onClick={() => {
                    setExpanded(false)
                    handleLogout()
                  }
                }>
                  Logout
                </Nav.Link>
              </>}

              {!currentUser && <Link className="nav-link" to="/login">
                  Login
                </Link>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ marginTop: "100px" }}>
        {error && <Alert variant="danger">{error}</Alert>}
        { children }
      </div>
    </>
  );
}
