import { Flight, launchFailureTypes } from "./Flight";
import { FlyingRate } from "./FlyingRate";

type FlightRatePair = {
  flight: Flight,
  rate: FlyingRate,
}

type CurrentCost = {
  cost: number,
  subsidisedMinutesUsed: number,
  rentsPaid: Array<string>,
}

export class CostCalculator {
  private static instance: CostCalculator;
  private static flyingRates: Array<FlyingRate>;

  private constructor () {
    CostCalculator.flyingRates = [];
  }
  
  public static getInstance(): CostCalculator {
    if (!CostCalculator.instance) {
      CostCalculator.instance = new CostCalculator();
    }

    return CostCalculator.instance;
  }

  public static setFlyingRates(
    flyingRates: Array<FlyingRate>,
  ) {
    CostCalculator.flyingRates = flyingRates
  }

  public static addFlyingRate(flyingRate: FlyingRate) {
    CostCalculator.flyingRates.push(flyingRate);
  }

  public static getFlyingRates() {
    return CostCalculator.flyingRates;
  }

  private static findFlyingRate(flight: Flight) {
    // Get rates for the given glider
    let possibleRates = CostCalculator.flyingRates.filter((flyingRate) => 
      flight.compNumber === flyingRate.rateName
    );

    // Get default rates if there is not one for the specified glider
    if (possibleRates.length === 0) {
      possibleRates = CostCalculator.flyingRates.filter((flyingRate) => 
        flyingRate.rateName === "default" || flyingRate.rateName === "defaultJunior"
      );
    }

    // Filter rates to those available for the current user
    possibleRates = possibleRates.filter((flyingRate) => 
      flight.p1.age <= flyingRate.maxAge
    );
    
    possibleRates = possibleRates.filter(flyingRate =>
      flyingRate.periodEnd > flight.date
    )

    // Select the latest rate
    possibleRates = possibleRates.sort((a, b) => 
      b.periodStart.valueOf() - a.periodStart.valueOf()
    )
    return possibleRates[0];
  }

  static calculateCost(flights: Array<Flight>): number {
    let flightRatePairs: Array<FlightRatePair> = flights.map((flight) => { 
      return {
        flight: flight,
        rate: CostCalculator.findFlyingRate(flight),
      }
    })
    
    //TODO: Check this sorts in DESCENDING order, otherwise swap round or reduce right
    //This is such that the subsidised minutes are calculated with the most expensive flights first
    flightRatePairs.sort((a, b) =>
      b.rate.subsidisedMinuteCost - a.rate.subsidisedMinuteCost
    )
    
    const cost = flightRatePairs.reduce((currentCost, flightRatePair) => {
      if(flightRatePair.flight.costOverride){
        currentCost.cost += flightRatePair.flight.costOverride;
      } else {
        currentCost = CostCalculator.flightCost( flightRatePair, currentCost );
      }
      return currentCost;
    },
    {
      cost: 0,
      subsidisedMinutesUsed: 0,
      rentsPaid: new Array<string>(),
    })
    return cost.cost;
  }  

  static flightCost(flightRatePair: FlightRatePair, currentCost: CurrentCost): CurrentCost {
    let newCost = currentCost.cost;
    
    let chargeableMinutes = Math.max(
      0,
      (
        flightRatePair.flight.length -
        (flightRatePair.rate.subsidisedAmountOfMinutes - Math.min(
          currentCost.subsidisedMinutesUsed,
          flightRatePair.rate.subsidisedAmountOfMinutes
        )
        )
      )
    )

    if(!flightRatePair.flight.freeLaunch) {
      switch (flightRatePair.flight.launchFailure) {
        case launchFailureTypes.rlf:
          // free launch and minutes
          chargeableMinutes = 0;
          break;
        case launchFailureTypes.tlf:
          // free minutes, subsidised launch
          newCost += flightRatePair.rate.subsidisedTLFCost
          chargeableMinutes = 0;
          break
        case launchFailureTypes.none:
          // chargeable launch and minutes
          newCost += flightRatePair.rate.subsidisedLaunchCost
          break;
        }
      }
      
    newCost += chargeableMinutes * flightRatePair.rate.subsidisedMinuteCost;

    let newRentsPaid = currentCost.rentsPaid;

    if (!currentCost.rentsPaid.includes(flightRatePair.rate.rateName)) {
      newRentsPaid.push(flightRatePair.rate.rateName);
      newCost += flightRatePair.rate.dailyRentalCost;
    }

    return {
      cost: newCost,
      subsidisedMinutesUsed: (flightRatePair.flight.length - chargeableMinutes) + currentCost.subsidisedMinutesUsed,
      rentsPaid: newRentsPaid,
    };
  }

  static approximateFlightCost(flight: Flight) {
    return CostCalculator.flightCost(
      {
        flight: flight,
        rate: CostCalculator.findFlyingRate(flight)
      },
      {
        cost: 0,
        subsidisedMinutesUsed: Infinity,
        rentsPaid: [],
      }
    )
  }
}
