import * as React from 'react';
import {  Col, Row } from "react-bootstrap";
import FlyingList from '../../components/admin/FlyingList';

export default function FlyingLists() {
  return (
    <Row>
      <Col md>
        <FlyingList />
      </Col>
      <Col md>
        <FlyingList />
      </Col>
      <Col md>
        <FlyingList />
      </Col>
    </Row>
  )
}
