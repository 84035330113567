import * as React from 'react'
import { useState, useEffect } from "react";
import { Card } from 'react-bootstrap';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table'
import { useApprovedContext } from '../../contexts/ApprovedContext';
import BootstrapTable from '../../table/BootstrapTable';
import { useAuth } from '../../contexts/AuthContext';
import { Payment } from '../../models/Payment';
import { PaymentsColumnBuilder } from '../../table/PaymentsColumnBuilder';

export default function Payments() {
  const { currentUser } = useAuth();
  const { getUsersPayments } = useApprovedContext()

  const [usersPayments, setUsersPayments] = useState<Array<Payment>>([]);

  const columnBuilder = new PaymentsColumnBuilder();
  columnBuilder.addDate();
  columnBuilder.addAmount();
  columnBuilder.addStatus();

  useEffect(() => {
    setPayments();
    async function setPayments() {
      setUsersPayments(currentUser ? await getUsersPayments(currentUser.uid) : [])
    }
  }, [setUsersPayments, getUsersPayments, currentUser])
 
  const table = useReactTable({
    data: usersPayments,
    columns: columnBuilder.getColumns(),
    getCoreRowModel: getCoreRowModel<Payment>(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  
  return (
    <Card>
      <Card.Header>
        <h2>Payments</h2>
        <p>The below shows any payments you have made, when they turn green and admin has marked that the payment has been received.</p>
      </Card.Header>
      <Card.Body>
        <BootstrapTable table={table}>
        </BootstrapTable>
      </Card.Body>
    </Card>
  )
}
