import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import {
  getDocs,
  collection,
} from "firebase/firestore";
import { DisplayedListContextInterface } from "./DisplayedListsContextInterface";
import { Airfield, BankDetailsType, ProviderProps, SelectableUser } from "./ContextTypes";
import { getFlights, timestampToDate } from "./Utils";
import { UserFlyingDay, userFlyingDayConverter } from "../models/UserFlyingDay";
import { Glider, gliderConverter } from "../models/Glider";
import { Payment, PaymentStatus, paymentsConverter } from "../models/Payment";
import { CostCalculator } from "../models/CostCalculator";
import { flyingRateConverter } from "../models/FlyingRate";
import { useAuth } from "./AuthContext";

const DBContext = React.createContext<DisplayedListContextInterface>({} as DisplayedListContextInterface);
const DETAILS: BankDetailsType = {
  fullName: "UNIVERSITY OF S'TON SU-STUDENTS GROUPS",
  sortCode: "30-90-34",
  accountNumber: "32684260",
}

export function useDisplayedListsContext() {
  return useContext(DBContext);
}

export function DisplayedListsProvider({ children }: ProviderProps): JSX.Element {
  const {currentUser, currentUserInfo} = useAuth();

  const [selectableUsers, setSelectableUsers] = useState<Array<SelectableUser>>([]);
  const [gliders, setGliders] = useState<Array<Glider>>([]);
  const [airfields, setAirfields] = useState<Array<Airfield>>([]);
  const [payments, setPayments] = useState<Array<Payment>>([]);
  const [unpublishedUserFlyingDays, setUnpublishedUserFlyingDays] = useState<Array<UserFlyingDay>>([]);
  const [bankDetails, setBankDetails] = useState<BankDetailsType>(DETAILS);

  useEffect(() => {
    if (!currentUser) return;
    if (!currentUserInfo) return;
    if (!currentUserInfo.isApproved) return;

    updateAirfields();
    updateGliders();
    setupCostCalculator();
    updateBankDetails();

    if (!currentUserInfo.isAdmin) return;
    updateUsers();
    updatePayments();
    updateUnpublishedUserFlyingDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentUserInfo]);

  async function setupCostCalculator() {
    CostCalculator.setFlyingRates(
      (await getDocs(
        collection(db, "flyingRates")
          .withConverter(flyingRateConverter))
      ).docs.map(
        doc => doc.data()
      )
    );
  }
  
  async function updateUsers() {
    setSelectableUsers(
      (await getDocs(collection(db, "users"))).docs.flatMap(user => {
        if (!user.get("approved")) return [];

        const dob = timestampToDate(user.get("dob"));

        return {
          uid: user.id,
          firstName: user.get("firstName"),
          lastName: user.get("lastName"),
          age: dob
            ?
            Math.abs((new Date(Date.now() - dob.getTime())).getUTCFullYear() - 1970)
            :
            50,
        }
      })
      .sort((userA, userB) => (userA.firstName + userA.lastName).localeCompare(userB.firstName + userB.lastName))
    )
  }

  async function updateGliders() {
    setGliders(
      (await getDocs(
        collection(db, "gliders")
          .withConverter(gliderConverter)
      )).docs.map(
        glider => glider.data()
      )
    )
  }

  async function updateAirfields() {
    setAirfields([
      {
        name: "Shalbourne",
        winchLaunchCost: 10,
        winchTLFCost: 3,
        winchRLFCost: 0,
      },
      {
        name: "Shalbourn",
        winchLaunchCost: 10,
        winchTLFCost: 3,
        winchRLFCost: 0,
      },
      {
        name: "Middle Wallop",
        winchLaunchCost: 0,
        winchTLFCost: 0,
        winchRLFCost: 0,
      },
    ])
  }

  async function updatePayments() {
    setPayments(
      (await getDocs(
        collection(
          db,
          "payments"
        )
          .withConverter(paymentsConverter)
      )).docs.map(
        doc => doc.data()
      ).filter(
        payment => payment.status === PaymentStatus.created
      ).sort(
        (paymentA, paymentB) => paymentA.datePaid.valueOf() - paymentB.datePaid.valueOf()
      )
    )
  }

  async function updateUnpublishedUserFlyingDays() {
    setUnpublishedUserFlyingDays(
      (await Promise.all(
        (await getDocs(
          collection(db, "unpublishedFlyingDays")
            .withConverter(userFlyingDayConverter)
        )).docs.map(
          async flyingDay => await getFlights(
            flyingDay.data(),
            collection(db, "unpublishedFlyingDays")
          )
        )
      ))
    );
  }

  async function updateBankDetails() {
    setBankDetails(DETAILS);
  }

  const value = {
    selectableUsers,
    gliders,
    airfields,
    payments,
    unpublishedUserFlyingDays,
    bankDetails,

    updateUsers,
    updateGliders,
    updateAirfields,
    updatePayments,
    updateUnpublishedUserFlyingDays,
    updateBankDetails,
  };

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>;
}
