import * as React from 'react';
import {useRef, useState} from 'react';
import { Alert, Button, Card, Form, Container, Row, Modal, Col } from 'react-bootstrap';
import { ChangeableUserDetails, SelectableUser, UserDetails, MemberType, UserState } from '../../contexts/ContextTypes';
import { useAdminContext } from '../../contexts/AdminContext';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';

export default function EditUser() {
  const { getUserDetails, updateUserDetails } = useAdminContext();
  const {selectableUsers} = useDisplayedListsContext();
  
  const [error, setError] = useState<string>("");
  const [formElemUserToEdit, setFormElemUserToEdit] = useState<SelectableUser>();
  const [editMode, setEditMode] = useState<boolean>(false);
  
  const [focusedUser, setFocusedUser] = useState<UserDetails | null>(null);
  
  const [currentChanges, setCurrentChanges] = useState<Array<JSX.Element>>([]);
  const [statefulNewUserDetails, setNewUserDetails] = useState<ChangeableUserDetails>({});
  const [modalShown, setModalShown] = useState(false);
  
  const [passengerCountShown, setPassengerCountShown] = useState(false);

  const typeaheadRef = useRef<typeof Typeahead>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const isDriverRef = useRef<HTMLInputElement>(null);
  const passengerCountRef = useRef<HTMLInputElement>(null);
  const memberTypeRef = useRef<HTMLSelectElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const userStateRef = useRef<HTMLSelectElement>(null);
  const freeFlightIDRef = useRef<HTMLInputElement>(null);

  function handleModalCancel() {
    setModalShown(false);
  }

  async function handleModalConfirm() {
    if (focusedUser === null)
      throw new Error("Tried to write without selecting a user");

    await updateUserDetails(focusedUser.uid, statefulNewUserDetails);
    handleResetUserToEdit();
    setModalShown(false);
  }

  function handleResetUserToEdit(e?: React.FormEvent<HTMLFormElement>) {
    if (e)
      e.preventDefault();

    setFocusedUser(null);
    setEditMode(false);
    typeaheadRef.current?.clear();
  }

  async function handleSelectUserToEdit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (formElemUserToEdit === undefined) return setError("Tried to select with no user chosen");

    const userDetails = await getUserDetails(formElemUserToEdit);
    setFocusedUser(userDetails);

    if (firstNameRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    firstNameRef.current.value = userDetails.firstName;

    if (lastNameRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    lastNameRef.current.value = userDetails.lastName;

    if (isDriverRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");

    setPassengerCountShown(false);
    if (userDetails.isDriver !== undefined) {
      isDriverRef.current.checked = userDetails.isDriver;
      setPassengerCountShown(userDetails.isDriver);
    }

    if (passengerCountRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    passengerCountRef.current.value = userDetails.passengerCount?.toString() ?? "";

    if (memberTypeRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    memberTypeRef.current.value = userDetails.memberType ? userDetails.memberType : "-";

    if (phoneNumberRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    phoneNumberRef.current.value = userDetails.phoneNumber ? userDetails.phoneNumber : "";

    if (userStateRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    if (userDetails.userState === undefined)
      throw new Error("User state was undefined in user details");
    userStateRef.current.value = userDetails.userState as unknown as string;

    if (freeFlightIDRef.current?.value === undefined)
      throw new Error("Reference had no field associated with it");
    freeFlightIDRef.current.value = userDetails.freeFlightID ? userDetails.freeFlightID : "";
    
  }

  async function handleWriteChanges(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError("");

    if (focusedUser === null)
      throw new Error("Tried to write without selecting a user");

    let editConfirmation: Array<JSX.Element> = [];
    let newUserDetails: ChangeableUserDetails = {};

    if (firstNameRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (firstNameRef.current.value !== focusedUser.firstName) {
      editConfirmation.push(<p>{`Firstname: ${focusedUser.firstName} -> ${firstNameRef.current.value}`}</p>);
      newUserDetails.firstName = firstNameRef.current.value;
    }

    if (lastNameRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (lastNameRef.current.value !== focusedUser.lastName) {
      editConfirmation.push(<p>{`Lastname: ${focusedUser.lastName} -> ${lastNameRef.current.value}`}</p>);
      newUserDetails.lastName = lastNameRef.current.value;
    }

    if (isDriverRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if(!(focusedUser.isDriver === undefined && isDriverRef.current.checked === false)) {
    if (isDriverRef.current.checked !== focusedUser.isDriver) {
      editConfirmation.push(<p>{`Is Driver: ${focusedUser.isDriver ? 'Yes' : 'No'} -> ${isDriverRef.current.checked ? 'Yes' : 'No'}`}</p>);
      newUserDetails.driver = isDriverRef.current.checked;
    }
    }

    if (passengerCountRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (passengerCountRef.current?.value === '' && isDriverRef.current?.checked) {
      setError("Drivers must have a passenger count");
      return;
    }

    if (!(passengerCountRef.current.value === "" && focusedUser.passengerCount === undefined)) {
      if (parseInt(passengerCountRef.current.value) !== focusedUser.passengerCount) {
        editConfirmation.push(<p>{`Passenger Count: ${focusedUser.passengerCount ? focusedUser.passengerCount : '-'} -> ${passengerCountRef.current.value}`}</p>);
        newUserDetails.passengerCount = parseInt(passengerCountRef.current.value);
      }
    }

    if (memberTypeRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (!(memberTypeRef.current.value === "-" && focusedUser.memberType === undefined)) {
      if (memberTypeRef.current.value !== focusedUser.memberType) {
        editConfirmation.push(<p>{`Member Type: ${focusedUser.memberType} -> ${memberTypeRef.current.value}`}</p>);
        newUserDetails.memberType = memberTypeRef.current.value as MemberType;
      }
    }

    if (phoneNumberRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (phoneNumberRef.current.value !== focusedUser.phoneNumber) {
      if (!(phoneNumberRef.current.value === '' && focusedUser.phoneNumber === undefined)){
        editConfirmation.push(<p>{`Phone Number: ${focusedUser.phoneNumber} -> ${phoneNumberRef.current.value}`}</p>);
        newUserDetails.phoneNumber = phoneNumberRef.current.value;
      }
    }

    if (userStateRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (focusedUser.userState === undefined)
      throw new Error("User state was undefined in user details");
    if (parseInt(userStateRef.current.value) !== focusedUser.userState) {
      editConfirmation.push(<p>{`User State: ${UserState[focusedUser.userState]} -> ${UserState[parseInt(userStateRef.current.value)]}`}</p>);
      newUserDetails.userState = parseInt(userStateRef.current.value) as UserState;
    }

    if (freeFlightIDRef.current?.value === undefined)
      throw new Error("Reference had not field associated with it");
    if (freeFlightIDRef.current.value !== focusedUser.freeFlightID) {
      if (!(freeFlightIDRef.current.value === '' && focusedUser.freeFlightID === undefined)){
        editConfirmation.push(<p>{`Free Flight ID: ${focusedUser.freeFlightID} -> ${freeFlightIDRef.current.value}`}</p>);
        newUserDetails.freeFlightID = freeFlightIDRef.current.value;
      }
    }

    setNewUserDetails(newUserDetails);
    setCurrentChanges(editConfirmation);
    setModalShown(true);
  }

  function handleEnterEditMode() {
    setEditMode(true);
  }

  return (
    <Card>
      <Modal show={modalShown}> 
        <Modal.Header>
          Are you sure you want to make the following changes...
        </Modal.Header>
        <Modal.Body>
          {currentChanges}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalConfirm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Card.Header>
        <h2>Edit User</h2>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <Container>
          <Row>
            {error && <Alert variant="danger">{error}</Alert>}
          </Row>
          <Row className='mt-0'>
            <Form onReset={handleResetUserToEdit} onSubmit={handleSelectUserToEdit}>
              <Form.Group id="userToEdit">
                <Form.Label>User to edit:</Form.Label>
                <Typeahead 
                  id="userToEditBox"
                  onChange={(selected: Array<SelectableUser>) => {
                    setFormElemUserToEdit(selected.pop());
                  }}
                  ref={typeaheadRef}
                  labelKey={(option: SelectableUser) => `${option.firstName} ${option.lastName}`}
                  options={selectableUsers}
                  disabled={focusedUser !== null}
                />
              </Form.Group>
              <Form.Group id="Buttons">
                <Row>
                  <Col className='text-center'>
                    <Button
                      type='reset'
                      disabled={focusedUser === null}
                      variant='warning'
                      className='m-1'
                    >
                      Reset
                    </Button>
                    <Button
                      type='submit'
                      disabled={(formElemUserToEdit === undefined || focusedUser !== null)}
                      variant='warning'
                      className='m-1'
                    >
                      Select
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Row>
          <Row>
            <Form hidden={focusedUser===null} className="correctly-margined-form"> 
              <Form.Group>
                <Form.Label> First Name ({focusedUser?.firstName}): </Form.Label>
                {/* <Form.Control hidden={!editMode} readOnly={true} disabled={true} type="text" id="firstnameOriginal" value={focusedUser === null ? "" : focusedUser.firstName } /> */}
                <Form.Control type="text" disabled={!editMode} id="firstname" ref={firstNameRef} />
              </Form.Group>
              <Form.Group>
                <Form.Label> Last Name ({focusedUser?.lastName}): </Form.Label>
                {/* <Form.Control hidden={!editMode} readOnly={true} disabled={true} type="text" id="lastnameOriginal" value={focusedUser === null ? "" : focusedUser.lastName } /> */}
                <Form.Control type="text" disabled={!editMode} id="lastname" ref={lastNameRef} />
              </Form.Group>
              <Form.Group>
                <Form.Label> Free Flight ID ({focusedUser?.freeFlightID}): </Form.Label>
                <Form.Control type="text" disabled={!editMode} id="freeFlightID" ref={freeFlightIDRef} />
              </Form.Group>
              <Form.Group>
                <Form.Label> Phone Number ({focusedUser?.phoneNumber}): </Form.Label>
                <Form.Control type="tel" disabled={!editMode} id="phoneNumber" ref={phoneNumberRef} />
              </Form.Group>
              <Form.Group>
                <Form.Label> User State ({focusedUser?.userState}): </Form.Label>
                <Form.Select ref={userStateRef} disabled={!editMode || focusedUser?.userState === UserState.admin}>
                  <option value={UserState.new}>({UserState.new}) New</option>
                  <option value={UserState.newOutstandingPayment}>({UserState.newOutstandingPayment}) New Outstanding Payment</option>
                  <option value={UserState.unapproved}>({UserState.unapproved}) Unapproved</option>
                  <option value={UserState.approved}>({UserState.approved}) Approved</option>
                  <option value={UserState.admin} disabled={true}>({UserState.admin}) Admin</option>
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label> Member Type ({focusedUser?.memberType ? focusedUser?.memberType : "-"}): </Form.Label>
                <Form.Select ref={memberTypeRef} disabled={!editMode}>
                  <option disabled={true}> - </option>
                  <option value={MemberType.trial}>Trial</option>
                  <option value={MemberType.home}>Home</option>
                  <option value={MemberType.away}>Away</option>
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label> Is Driver ({focusedUser?.isDriver ? 'Yes' : 'No'}): </Form.Label>
                <Form.Check type="switch" disabled={!editMode} id="isDriver" ref={isDriverRef} onChange={() => setPassengerCountShown(isDriverRef.current ? isDriverRef.current.checked : false)} />
              </Form.Group>
              <Form.Group hidden={!passengerCountShown}>
                <Form.Label> Passenger Count ({focusedUser?.passengerCount ? focusedUser?.passengerCount : '-'}): </Form.Label>
                <Form.Control type="number" step='1' disabled={!editMode} id="passengerCount" ref={passengerCountRef} />
              </Form.Group>
            </Form>
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer hidden={focusedUser === null}>
        <Form onSubmit={handleWriteChanges}>
          <Row>
            <Col className='text-center'>
              <Button className='m-1' type='button' variant='warning' onClick={handleEnterEditMode} disabled={editMode}>
                Edit
              </Button>
              <Button className="m-1" type='submit' variant='danger' disabled={!editMode}>
                Write Changes
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Footer>
    </Card>
  )
}
