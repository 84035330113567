import * as React from 'react';
import {  Col, Row, Tab, Tabs } from "react-bootstrap";
import UnapprovedUserList from '../../components/admin/UnapprovedUserList';
import PaymentApproval from '../../components/admin/PaymentApproval';
import AddFlights from './AddFlights';
import FlyingLists from './FlyingLists';
import MemberManagement from './MemberManagement';

export default function Admin() {
  return (
    <div className='vw-100 vh-100'>
      <Tabs
        defaultActiveKey={"admin"}
        id="tabs"
        variant='pills'
        fill
        justify
        className='mx-2 my-2 '
      >
        <Tab
          eventKey="admin"
          title="Admin"
          className='mx-2 my-2'
        >
          <Row>
            <Col lg>
              <UnapprovedUserList showBinnedUsers={false}/>
            </Col>
            <Col lg className='d-none d-md-block'>
              <PaymentApproval />
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey="flyingLists"
          title="Flying Lists"
          className='mx-2 my-2'
        >
          <FlyingLists />
        </Tab>
        <Tab
          eventKey="flightInput"
          title="Flight Input"
          className='mx-2 my-2'
        >
          <AddFlights />
        </Tab>
          <Tab
            eventKey="memberManagement"
            title="Member Management"
            className='mx-2 my-2'
          >
            <MemberManagement />
          </Tab>
      </Tabs>
    </div>
  )
}
