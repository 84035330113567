import * as React from 'react';
import {useRef, useState} from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { SelectableUser } from '../../contexts/ContextTypes';
import { launchFailureTypes, launchTypes } from '../../models/Flight';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';
import { useAdminContext } from '../../contexts/AdminContext';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function FlightInput() {

  const {gliders, airfields, selectableUsers, updateUnpublishedUserFlyingDays} = useDisplayedListsContext();
  const {addFlight} = useAdminContext();
  const [error, setError] = useState<string>("");

  const date = useRef<HTMLInputElement>(null);
  const length = useRef<HTMLInputElement>(null);
  const [p1, setP1] = useState<SelectableUser>();
  const [p2, setP2] = useState<SelectableUser>();
  const reg = useRef<HTMLSelectElement>(null);
  const launchType = useRef<HTMLSelectElement>(null);
  const launchFailure = useRef<HTMLSelectElement>(null);
  const freeLaunch = useRef<HTMLInputElement>(null);
  const launchLocation = useRef<HTMLSelectElement>(null);

  function checkBlockFOCandRLF(){
    if (launchFailure.current && freeLaunch.current) {
      if (launchFailure.current.value === "rlf") {
        freeLaunch.current.checked = false;
        freeLaunch.current.disabled = true;
      } else {
        freeLaunch.current.disabled = false;
      }
    }
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError("");

    if (!date.current) return setError("Invalid flight date");
    try {
      if (!date.current.valueAsDate) return setError("Invalid flight date");
    } catch (error) {
      return setError("Invalid flight date");
    }
    if (date.current.valueAsDate.valueOf() > (new Date()).valueOf()) 
      return setError("Invalid flight date");
    
    if (!length.current) return setError("Please select a length")
    if (length.current.valueAsNumber <= 0) return setError("Length of flight must be greater than 0");

    if (p1 === undefined) return setError("P1 Must be selected");

    if (!reg.current) return setError("Please select a glider");

    if (!launchType.current) return setError("Please select a launch type");
    if (!(launchType.current.value in launchTypes)) return setError("How did we get here then...")
    const castLaunchType = launchType.current.value as launchTypes;

    if (!launchFailure.current) return setError("Please select a launch failure option");
    if (!(launchFailure.current.value in launchFailureTypes)) return setError("How did we get here then...")
    const castLaunchFailure = launchFailure.current.value as launchFailureTypes;

    if (!freeLaunch.current) return setError("How did we get here then...");
    
    if (!launchLocation.current) return setError("Please select a launch location");
    
    addFlight(
      date.current.valueAsDate,
      length.current.valueAsNumber,
      reg.current.value,
      castLaunchType,
      castLaunchFailure,
      launchLocation.current.value,
      p1,
      (p2?.firstName === "--Solo--") ? undefined : p2,
      freeLaunch.current.checked,
    ).then(() => {
      updateUnpublishedUserFlyingDays()
    });

    // helpful to leave the other values for next submission but dangerous to leave this one on
    freeLaunch.current.checked = false;
  }

  return (
    <Card>
      <Card.Header>
        <h2>Add A Flight</h2>

      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <Form onSubmit={onSubmit} className='correctly-margined-form'>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group id="date">
            <Form.Label>Date:</Form.Label>
            <Form.Control
              type='date'
              ref={date}
              required
            />
          </Form.Group>
          <Form.Group id="Length">
            <Form.Label>Length (mins):</Form.Label>
            <Form.Control 
              type='number'
              ref={length}
              required
            />
          </Form.Group>
          <Form.Group id="p1">
            <Form.Label>Billing Member</Form.Label>
            <Typeahead 
              id="P1Selector"
              onChange={(selected: Array<SelectableUser>) => {
                setP1(selected.pop());
              }}
              labelKey={(option: SelectableUser) => `${option.firstName} ${option.lastName}`}
              options={selectableUsers}
            />
          </Form.Group>
          <Form.Group id="p2">
            <Form.Label>Other Pilot</Form.Label>
            <Typeahead 
              id="P2Selector"
              onChange={(selected: Array<SelectableUser>) => {
                setP2(selected.pop());
              }}
              placeholder="--Instructor--"
              labelKey={(option: SelectableUser) => `${option.firstName} ${option.lastName}`}
              options={[{firstName: "--Solo--", lastName: "", age: 50, uid: -1},{firstName: "--Instructor--", lastName: "", age: 50, uid: -1}, ...selectableUsers]}
            />
          </Form.Group>
          <Form.Group id="reg">
            <Form.Label>Glider Reg:</Form.Label>
            <Form.Select
              ref={reg}
              required
            >
              {gliders.map((glider) => 
                <option key={glider.compNumber} value={glider.compNumber}>
                  {glider.compNumber}
                </option>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group id="launchType">
            <Form.Label>Launch Type:</Form.Label>
            <Form.Select
              ref={launchType}
              required
            >
              {(Object.keys(launchTypes) as Array<keyof typeof launchTypes>).map((launchType) => 
                <option key={launchType} value={launchType}>
                  {launchTypes[launchType]}
                </option>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group id="launchFailure">
            <Form.Label>Launch Failure?:</Form.Label>
            <Form.Select
              ref={launchFailure}
              onChange={checkBlockFOCandRLF}
              required
            >
              {(Object.keys(launchFailureTypes) as Array<keyof typeof launchFailureTypes>).map((failureType) => 
                <option key={failureType} value={failureType}>
                  {launchFailureTypes[failureType]}
                </option>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group id="freeLaunch">
            <Form.Check 
              type="checkbox"
              label="FOC Launch"
              ref={freeLaunch}
            />
          </Form.Group>
          <Form.Group id="launchLocation">
            <Form.Label>Launch Location:</Form.Label>
            <Form.Select
              ref={launchLocation}
              required
            >
              {/*You may ask "Why callum did you not just remove Shalbourns existance all together", and the answer is:
                "Because fuck you thats why"
                (but really its vecause I have no faith that its not gonna get refferenced somewhere and mess up cost approximation
              */}
              {airfields.filter(airfield => airfield.name !== "Shalbourn").map((airfield) => 
                <option key={airfield.name} value={airfield.name}>
                  {airfield.name}
                </option>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-0 mt-1'>
            <Button className='w-100 mt-3' type='submit'>Submit</Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
