import * as React from 'react';
import { useRef, useState } from "react";
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { FlyingRate } from '../../models/FlyingRate';
import { useAdminContext } from '../../contexts/AdminContext';
import { Glider } from '../../models/Glider';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CostCalculator } from '../../models/CostCalculator';

export default function GliderGeneration() {
  const { addGlider } = useAdminContext();

  const [loading, setLoading] = useState<boolean>(false);

  const reg = useRef<HTMLInputElement>(null);
  const compNumber = useRef<HTMLInputElement>(null);
  const type = useRef<HTMLInputElement>(null);
  const [rate, setRate] = useState<FlyingRate>()
  const [juniorRate, setJuniorRate] = useState<FlyingRate>()
  
  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setLoading(true);

    if (
		  ! reg.current ||
      ! compNumber.current ||
      ! type.current
    ) return;

    await addGlider(new Glider(
      compNumber.current.value,
		  reg.current.value,
      type.current.value,
      (rate) ? rate.rateName : "default",
      (juniorRate) ? juniorRate.rateName : (rate) ? rate.rateName : "default",
    ));

    (document.getElementById("addGliderForm") as HTMLFormElement).reset();
    setLoading(false);
  }

  return (
    <Card>
      <Card.Header>
        <h2>Add A Glider</h2>
        <Alert variant='danger'>
          This has ZERO input protection - don't use it unless you have access to firebase to finalize the result and have been shown how to.
        </Alert>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <Form id="addGliderForm" onSubmit={onSubmit}>
          <Form.Group id="compNumber">
            <Form.Label>Comp Number:</Form.Label>
            <Form.Control
              type='text'
              ref={compNumber}
              required
            />
          </Form.Group>
          <Form.Group id="reg">
            <Form.Label>Reg:</Form.Label>
            <Form.Control
              type='text'
              ref={reg}
              required
            />
          </Form.Group>
          <Form.Group id="type">
            <Form.Label>Type:</Form.Label>
            <Form.Control
              type='text'
              ref={type}
              required
            />
          </Form.Group>
          <Form.Group id="juniorRateName">
            <Form.Label>Junior Rate Name:</Form.Label>
            <Typeahead 
              id="juniorRateNameSelector"
              onChange={(selected: Array<FlyingRate>) => {
                setJuniorRate(selected.pop());
              }}
              placeholder={(rate) ? rate.rateName : "default"}
              labelKey={(option: FlyingRate) => option.rateName}
              options={CostCalculator.getFlyingRates()}
            />
          </Form.Group>
          <Form.Group id="rateName">
            <Form.Label>Rate Name:</Form.Label>
            <Typeahead 
              id="rateNameSelector"
              onChange={(selected: Array<FlyingRate>) => {
                setRate(selected.pop());
              }}
              placeholder="default"
              labelKey={(option: FlyingRate) => option.rateName}
              options={CostCalculator.getFlyingRates()}
            />
          </Form.Group>
          <Form.Group>
            <Button disabled={loading} className='w-100 mt-3' type='submit'>Submit</Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
