import * as React from 'react';
import {  Col, Row } from "react-bootstrap";
import FlightInput from '../../components/admin/FlightInput';
import UnpublishedFlights from '../../components/admin/UnpublishedFlights';
import FlightImport from '../../components/admin/FlightImport';

export default function AddFlights() {
  return (
    <Row>
      <Col sm>
        <FlightInput />
      </Col>
      <Col sm>
        <FlightImport />
      </Col>
      <Col lg className='d-none d-md-block'>
        <UnpublishedFlights />
      </Col>
    </Row>
  )
}
