import * as React from "react";
import { useContext } from "react";
import { db } from "../firebase";
import {
  getDocs,
  collection,
  Timestamp,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
  DocumentSnapshot,
  DocumentData,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
} from "firebase/firestore"
import { AdminContextModel } from "./AdminContextInterface";
import { ChangeableUserDetails, ProviderProps, SelectableUser, UserDetails, UserInfo } from "./ContextTypes";
import { addFlyingDayToCollection, getFlights, getUserList, timestampToDate } from "./Utils";
import { Payment, PaymentStatus } from "../models/Payment";
import { useDisplayedListsContext } from "./DisplayedListsContext";
import { Status, UserFlyingDay } from "../models/UserFlyingDay";
import { Flight, flightConverter } from "../models/Flight";
import { FlyingRate, flyingRateConverter } from "../models/FlyingRate";
import { CostCalculator } from "../models/CostCalculator";
import { Glider, gliderConverter } from "../models/Glider";
import { useAuth } from "./AuthContext";

const DBContext = React.createContext<AdminContextModel>({} as AdminContextModel);

export function useAdminContext() {
  return useContext(DBContext);
}

const userDocumentReference = (uid: string) => doc(db, "users", uid);

const usersListReference = () => collection(db, "users");
const unapprovedUsersListReference = () => collection(db, "unapprovedUsers");
const binnedUnapprovedUsersListReference = () => collection(db, "unapprovedUsersBin");

const userAvailabilityReference = (date: Date, uid: string) =>
  doc(db, "users", uid, "availability", Timestamp.fromDate(date).valueOf());

const flyingListReference = (date: Date) =>
  collection(
    db,
    "availability",
    Timestamp.fromDate(date).valueOf(),
    "flyingList"
  );

const signedUpListReference = (date: Date) =>
  collection(
    db,
    "availability",
    Timestamp.fromDate(date).valueOf(),
    "signedUp"
  );

const flyingDayReference = (date: Date) =>
  doc(db, "availability", Timestamp.fromDate(date).valueOf());

export function AdminProvider({ children }: ProviderProps): JSX.Element {
  const { currentUser, currentUserInfo } = useAuth();
  const { updatePayments, unpublishedUserFlyingDays, updateUnpublishedUserFlyingDays } = useDisplayedListsContext();

  async function getFlyingListInfo(date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    var promises: Array<
      Promise<Array<UserInfo> | DocumentSnapshot<DocumentData>>
    > = [];

    promises.push(getUserList(signedUpListReference(date)));

    promises.push(getUserList(flyingListReference(date)));

    promises.push(getDoc(flyingDayReference(date)));

    const results = await Promise.all(promises);
    if (
      !(results[0] instanceof Array) ||
      !(results[1] instanceof Array) ||
      !(results[2] instanceof DocumentSnapshot)
    )
      throw new Error("Great programming resulted in an impossible state");

    return {
      availableUsers: results[0],
      currentFlyingList: results[1],
      final: results[2].get("final") ? true : false,
    };
  }

  async function addUserToFlyingList(userInfo: UserInfo, date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    delete userInfo.email;
    delete userInfo.final;
    delete userInfo.owes;
    delete userInfo.lastFlew;
    delete userInfo.userState;
    delete userInfo.freeFlightID;
    if (!userInfo.isDriver) {
      delete userInfo.isDriver;
      delete userInfo.daysDriver;
      delete userInfo.passengerCount;
    } else {
      userInfo.daysDriver = true;
    }
    if (!userInfo.memberType) {
      delete userInfo.memberType;
    }

    return await setDoc(doc(flyingListReference(date), userInfo.uid), userInfo);
  }

  async function toggleUserDriverStatus(userInfo: UserInfo, date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await updateDoc(doc(flyingListReference(date), userInfo.uid), {
      daysDriver: !userInfo.daysDriver,
    })
  };

  async function removeUserFromFlyingList(userInfo: UserInfo, date: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await deleteDoc(doc(flyingListReference(date), userInfo.uid));
  }
  
  async function finalizeFlyingList(
    date: Date,
    leavingTime: number,
    notes: String
  ) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    const leavingDate = new Date(date);
    leavingDate.setHours(leavingTime);
    leavingDate.setMinutes(60 * (leavingTime - Math.floor(leavingTime)));

    const finalizeJobs = writeBatch(db);

    const signedUpUsers = await getDocs(signedUpListReference(date));
    let driverReference: string | undefined = undefined;

    await Promise.all(
      signedUpUsers.docs.map(async (user) => {
        finalizeJobs.update(userAvailabilityReference(date, user.id), {
          final: true,
        });

        const currentUserIsDriver = user.get("isDriver");
        if (currentUserIsDriver === undefined) return;
        if (currentUserIsDriver === false) return;

        const flyingUserProfile = await getDoc(doc(flyingListReference(date), user.id));
        if (flyingUserProfile.exists() === false) return;

        const currentUserIsDaysDriver = flyingUserProfile.get("daysDriver");
        if (currentUserIsDaysDriver === undefined) return;
        if (currentUserIsDaysDriver === false) return;

        driverReference = user.id;
    }));

    if (driverReference === undefined) {
      driverReference = currentUser.uid;
    }

    finalizeJobs.set(
      flyingDayReference(date),
      {
        final: true,
        leavingTime: Timestamp.fromDate(leavingDate),
        notes: notes,
        driverId: driverReference,
      },
      {
        merge: true,
      }
    );


    return await finalizeJobs.commit();
  }

  function getTotalUserList() {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");
    
    return getUserList(usersListReference(), false);
  }

  function getUnapprovedUsersList(binnedUsers: boolean = false) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");
    
    if (binnedUsers)
      return getUserList(binnedUnapprovedUsersListReference());
    else
      return getUserList(unapprovedUsersListReference());
  }

  async function approveUser(uid: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    const batch = writeBatch(db);

    batch.update(userDocumentReference(uid), {
      approved: true,
      userState: 3,
    });

    batch.delete(doc(unapprovedUsersListReference(), uid));

    await batch.commit();
  }

  async function binUnapprovedUser(uid: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    const user = await getDoc(doc(unapprovedUsersListReference(), uid))

    const firstName   =                 user.get("firstName"  ) ;
    const lastName    =                 user.get("lastName"   ) ;
    const email       =                 user.get("email"      ) ;
    const dateCreated = timestampToDate(user.get("dateCreated"));
    const memberType  =                 user.get("memberType" ) ;

    if (firstName   === undefined) throw new Error("FUCK FUCK FUCK");
    if (lastName    === undefined) throw new Error("FUCK FUCK FUCK");
    if (email       === undefined) throw new Error("FUCK FUCK FUCK");
    if (dateCreated === undefined) throw new Error("FUCK FUCK FUCK");

    const batch = writeBatch(db);

    batch.delete(doc(unapprovedUsersListReference      (), uid));

    // Use of an if statement here on membertype is suboptimal, and if more
    // fields ever need to be added this should be changed, but it works as
    // a hotfix
    if (memberType === undefined)
      batch.set   (doc(binnedUnapprovedUsersListReference(), uid), {
        firstName   : ((firstName   === undefined) ? "FUCK" : firstName),
        lastName    : ((lastName    === undefined) ? "FUCK" : lastName ),
        email       : ((email       === undefined) ? "FUCK" : email    ),
        dateCreated : ((dateCreated === undefined) ? Timestamp.now() : Timestamp.fromDate(dateCreated)),
      })
    else
      batch.set   (doc(binnedUnapprovedUsersListReference(), uid), {
        firstName   : ((firstName   === undefined) ? "FUCK" : firstName),
        lastName    : ((lastName    === undefined) ? "FUCK" : lastName ),
        email       : ((email       === undefined) ? "FUCK" : email    ),
        dateCreated : ((dateCreated === undefined) ? Timestamp.now() : Timestamp.fromDate(dateCreated)),
        memberType  : memberType
      })

    await batch.commit();
  }

  async function approvePayments(payments: Array<Payment>) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await Promise.all(
      payments.flatMap(payment => [
        updateDoc(
          doc(
            db,
            "payments",
            payment.id,
          ),
          {
            status: PaymentStatus.approved
          }
        ),
        updateDoc(
          doc(
            db,
            "users",
            payment.paidBy.uid,
            "payments",
            payment.id
          ),
          {
            status: PaymentStatus.approved
          }
        ),
        payment.paysOff.flatMap(ref => [
          updateDoc(
            doc(
              db,
              "users",
              payment.paidBy.uid,
              "flyingDays",
              ref
            ),
            {
              status: Status.Paid
            }
          ),
          updateDoc(
            doc(
              db,
              "flyingDays",
              ref
            ),
            {
              status: Status.Paid
            }
          ),
        ])
      ])
    )
    updatePayments();
    updateUserOwes(payments.map(payment => payment.paidBy.uid));
  }

  async function updateUserOwes(uids: Array<string>): Promise<void> {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    uids.forEach(async uid => {
      let userOwes = (await getDocs(
        collection(
          db,
          "users",
          uid,
          "flyingDays"
        )
      ))
      .docs
      .filter(
        flyingDay => (flyingDay.get("status") === Status.Unpaid)
      )
      .reduce(
        (owes, flyingDay) => owes + (flyingDay.get("cost") === undefined ? 0 : flyingDay.get("cost")),
        0
      );

      updateDoc(
        doc(db, "users", uid),
        {
          owes: userOwes
        }
      )
    });
  }

  async function deleteUnpublishedDay(
    date: Date,
    user: SelectableUser,
  ) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    let userFlyingDay = unpublishedUserFlyingDays.find(
      (userFlyingDay) => userFlyingDay.equals(
        date,
        user.uid,
      )
    );

    if (userFlyingDay === undefined) throw new Error("User flying day is not stored");

    const userFlyingDayDoc = doc(
      db,
      "unpublishedFlyingDays",
      userFlyingDay.getDayReference(),
    );

    const userFlyingDayFlights = collection(userFlyingDayDoc, "flights");
    const userFlyingDayFlightsDocs = await getDocs(userFlyingDayFlights);

    const batch = writeBatch(db);

    userFlyingDayFlightsDocs.forEach(flightDoc => batch.delete(
      doc(
        db,
        "flights",
        flightDoc.id,
    )));

    userFlyingDayFlightsDocs.forEach(flightDoc => batch.delete(
      doc(
        userFlyingDayFlights,
        flightDoc.id,
    )));

    batch.delete(
      doc(
        db,
        "unpublishedFlyingDays",
        userFlyingDay.getDayReference(),
    ));

    await batch.commit();

    await updateUnpublishedUserFlyingDays();
  }

  async function addFlight(
    date: Date,
    length: number,
    compNumber: string,
    launchType: string,
    launchFailure: string,
    launchLocation: string,
    p1: SelectableUser,
    p2: SelectableUser | undefined,
    freeLaunch: boolean,
    costOverride?: number,
    freeFlightId?: string,
  ) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    let userFlyingDay = unpublishedUserFlyingDays.find(
      (userFlyingDay) => userFlyingDay.equals(
        date,
        p1.uid,
      )
    );

    if (userFlyingDay === undefined)
      userFlyingDay = new UserFlyingDay(
        date,
        p1,
        0,
        0,
        Status.Unpublished,
      );
    else
      userFlyingDay = await getFlights(
        userFlyingDay,
        collection(db, "unpublishedFlyingDays")
      );

    let flight = new Flight(
      undefined,
      date,
      length,
      compNumber,
      launchType,
      launchFailure,
      launchLocation,
      p1,
      p2,
      freeLaunch,
      costOverride,
      freeFlightId,
    )

    flight.id = (await addDoc(
      collection(db, "flights")
        .withConverter(flightConverter),
      flight
    )).id

    userFlyingDay.addFlight(flight);

    await addFlyingDayToCollection(
      userFlyingDay,
      collection(db,
        "unpublishedFlyingDays",
      )
    );
  }

  async function publishFlyingDays(flyingDays: Array<UserFlyingDay>) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    flyingDays.forEach(flyingDay => flyingDay.status = Status.Unpaid);
    await Promise.all(
      flyingDays.flatMap(
        day => [
          addFlyingDayToCollection(day, collection(db, "flyingDays")),
          addFlyingDayToCollection(day, collection(db, "users", day.user.uid, "flyingDays")),
          deleteDoc(doc(
            db,
            "unpublishedFlyingDays",
            day.getDayReference(),
          ))
        ]
      )
    )
    await updateUnpublishedUserFlyingDays();

    // This should filter the list of flying days being published to only include
    // the latest day for each user
    flyingDays.sort((a, b) => a.date.getUTCSeconds() - b.date.getUTCSeconds());
    flyingDays.filter((day, index, currentFlyingDays) => (currentFlyingDays.indexOf(day) === index))

    // I should check that the current last flew date is less than the new one I'm about to set... but I'm not going to cos dealing with edge cases is for pussys
    flyingDays.forEach(flyingDay => {
      updateDoc(
        doc(db, "users", flyingDay.user.uid),
        {
          "lastFlew" : flyingDay.date
        }
      );
    });
    updateUserOwes(flyingDays.map(flyingDay => flyingDay.user.uid));
  }

  async function getUserDetails(user : string | SelectableUser) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");
        
    const uid = (typeof user === "string") ? user : user.uid;

    const userInfoDoc = await getDoc(
      doc(
        db,
        "users",
        uid
      )
    );

    const dob = timestampToDate(userInfoDoc.get("dob"))
    if (dob === undefined) throw new Error("Cannot get the userInfo for a user who was never born")

    let userDetails : UserDetails = {
      uid,
      firstName: userInfoDoc.get("firstName"),
      lastName: userInfoDoc.get("lastName"),
      email: userInfoDoc.get("email"),
      dob,
      isDriver: userInfoDoc.get("driver"),
      passengerCount: userInfoDoc.get("passengerCount"),
      phoneNumber: userInfoDoc.get("phoneNumber"),
      userState: userInfoDoc.get("userState"),
      memberType: userInfoDoc.get("memberType"),
      freeFlightID: userInfoDoc.get("freeFlightID"),
    }

    return userDetails;
  }

  async function updateUserDetails(uid: string, newUserDetails : ChangeableUserDetails) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");
    
    const originalUserDetails = await getUserDetails(uid)
    let updatedFields: ChangeableUserDetails = {};

    console.log(`Requested to update from driver status ${originalUserDetails.isDriver} to ${newUserDetails.driver}`);
    if (newUserDetails.driver !== originalUserDetails.isDriver && newUserDetails.driver !== undefined)
      updatedFields.driver = newUserDetails.driver;

    if (newUserDetails.firstName !== originalUserDetails.firstName && newUserDetails.firstName !== undefined)
      updatedFields.firstName = newUserDetails.firstName;

    if (newUserDetails.lastName !== originalUserDetails.lastName && newUserDetails.lastName !== undefined)
      updatedFields.lastName = newUserDetails.lastName;

    if (newUserDetails.userState !== originalUserDetails.userState && newUserDetails.userState !== undefined)
      updatedFields.userState = newUserDetails.userState;

    if (newUserDetails.memberType !== originalUserDetails.memberType && newUserDetails.memberType !== undefined)
      updatedFields.memberType = newUserDetails.memberType;
    
    if (newUserDetails.phoneNumber !== originalUserDetails.phoneNumber && newUserDetails.phoneNumber !== undefined)
      updatedFields.phoneNumber = newUserDetails.phoneNumber;

    if (newUserDetails.passengerCount !== originalUserDetails.passengerCount && newUserDetails.passengerCount !== undefined)
      updatedFields.passengerCount = newUserDetails.passengerCount;

    if (newUserDetails.freeFlightID !== originalUserDetails.freeFlightID && newUserDetails.freeFlightID !== undefined)
      updatedFields.freeFlightID = newUserDetails.freeFlightID;

    // Update the users main document
    await updateDoc(
      doc(db, "users", uid),
      updatedFields
    );

    // Propogate changes accross all non final availabilitys
    delete updatedFields.userState;
    (
      await getDocs(
        collection(db,
          "users",
          uid,
          "availability"
        ))
    )
    .docs
    .filter((availabilityReference) => {
        const availabilityDate = timestampToDate(availabilityReference.get("dateRequested"));
        if (availabilityDate === undefined) return false;
        if (availabilityReference.get("final") === true) return false
        return (availabilityDate > new Date())
      }
    ).forEach(async (availabilityReference) => {
        updateDoc(doc(
          db,
          "availability",
          availabilityReference.id,
          "signedUp",
          uid
        ),
          updatedFields
        );
         
        const flyingListDoc = doc(
          db,
          "availability",
          availabilityReference.id,
          "flyingList",
          uid
        )

        if ((await getDoc(flyingListDoc)).exists())
          updateDoc(
            flyingListDoc,
            updatedFields
          );
      }
    )
  }

  async function addFlyingRate(flyingRate: FlyingRate) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    CostCalculator.addFlyingRate(flyingRate);

    await addDoc(
      collection(db, "flyingRates")
        .withConverter(flyingRateConverter),
      flyingRate
    )
  }

  async function addGlider(glider: Glider) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await addDoc(
      collection(db, "gliders")
        .withConverter(gliderConverter),
      glider
    );
  }

  async function updateUserFreeFlightID(uid: string, freeFlightID: string) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    await updateDoc(doc(db, "users", uid), {
      freeFlightID: freeFlightID,
    });
    //await updateUsers();
  }

  async function getAllFlightsDateRange(start: Date, end: Date) {
    if (!currentUser) throw new Error("User is not signed in");
    if (!currentUserInfo) throw new Error("User is not in DB");
    if (!currentUserInfo.isAdmin) throw new Error("User is not an admin");

    // create empty array to start
    let flights = Array<Flight>();

    const existingFlightsQuery = query(
      collection(db, "flights"),
      where("date", ">=", Timestamp.fromDate(start)),
      where("date", "<=", Timestamp.fromDate(end))
    ).withConverter(flightConverter);

    const existingFlightsSnapshot = await getDocs(existingFlightsQuery);
    existingFlightsSnapshot.forEach((doc) => {
      flights.push(doc.data());
    });

    const unpublishedFlightsQuery = query(
      collection(db, "unpublishedFlyingDays"),
      where("date", ">=", Timestamp.fromDate(start)),
      where("date", "<=", Timestamp.fromDate(end))
    );

    const unpublishedSnapshot = await getDocs(unpublishedFlightsQuery);
    unpublishedSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.flights === undefined) return
      flights.push(...data.flights);
    });

    return flights;
  }

  const value = {
    getFlyingListInfo,
    addUserToFlyingList,
    toggleUserDriverStatus,
    removeUserFromFlyingList,
    finalizeFlyingList,

    getTotalUserList,
    getUnapprovedUsersList,
    approveUser,
    binUnapprovedUser,

    approvePayments,

    deleteUnpublishedDay,

    addFlight,
    publishFlyingDays,
    
    updateUserFreeFlightID,
    getAllFlightsDateRange,

    getUserDetails,
    updateUserDetails,

    addFlyingRate,
    addGlider,
    
  };

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>;
}
