import * as React from 'react'
import {useEffect, useState} from 'react';
import { Card, Button, Table } from 'react-bootstrap'
import { MemberType, UserInfo } from '../../contexts/ContextTypes';
import {ReactComponent as Add} from "../../imgs/add.svg"
import { useAdminContext } from '../../contexts/AdminContext';
import RemoveButton from '../../table/RemoveButton';

interface UnapprovedUserListProps {
  showBinnedUsers: boolean,
}

export default function UnapprovedUserList(props : React.PropsWithChildren<UnapprovedUserListProps>) {
  const { getUnapprovedUsersList, approveUser, binUnapprovedUser } = useAdminContext(); 
  const [unapprovedUserRows, setUnapprovedUserRows] = useState<Array<JSX.Element>>([]);
  const [updateSub, updatePub] = useState<number>(0);
  useEffect(() => {
    getUnapprovedUsersList(props.showBinnedUsers).then((res) => {
      setUnapprovedUserRows(res.map(availableDateToTableRow));
    });

    function availableDateToTableRow(unapprovedUser : UserInfo) : JSX.Element {
      return (
        <tr key={unapprovedUser.uid}>
          <td className='text-center'>
            <>{`${unapprovedUser.firstName} ${unapprovedUser.lastName}`}</>
            <>{unapprovedUser.memberType === MemberType.trial && <span className="badge bg-warning text-dark ms-2">TRIAL</span>}</>
          </td>
          <td className='text-center'>{unapprovedUser.email}</td>
          <td className='text-center'>{unapprovedUser.dateCreated.toDateString().substring(4)}</td>
          <td className='text-center'>
            {/*TODO: Make this the same as the bin button*/}
            <Button variant='success' className='d-inline-flex' onClick={async () => {
              await approveUser(unapprovedUser.uid);
              updatePub(updateSub + 1);
            }}>
              <Add className='bi bi-trash' height="15" width="15"/>
            </Button>
          </td>
          {!props.showBinnedUsers &&
            <td className='text-center'>
                <RemoveButton
                  onClick={async () => {
                    await binUnapprovedUser(unapprovedUser.uid);
                    updatePub(updateSub + 1);
                  }}
                  disabled={false}
                  hoverMessage={"Move user to unnaproved users bin"}
                />
            </td>
          }
        </tr>
      );
    }
  }, [getUnapprovedUsersList, approveUser, binUnapprovedUser ,updateSub, props.showBinnedUsers]);
  
  return (
    <Card>
      <Card.Header>
        <h2>Unapproved Users</h2>
      </Card.Header>
      <Card.Body>
        <Table>
            <thead>
              <tr>
                <th className='text-center'>Name</th>
                <th className='text-center'>Email</th>
                <th className='text-center'>Sign Up Date</th>
                <th className='text-center'>Approve</th>
                {!props.showBinnedUsers &&
                  <th className='text-center'>Bin</th>
                }
              </tr>
            </thead>
            <tbody>
              {unapprovedUserRows}
            </tbody>
          </Table>
      </Card.Body>
    </Card>
  )
}
