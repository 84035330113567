import * as React from 'react';
import { useRef, useState } from 'react';
import { Alert, Button, Card, Form, Modal } from 'react-bootstrap';
import { SelectableUser } from '../../contexts/ContextTypes';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';
import { useAdminContext } from '../../contexts/AdminContext';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { launchTypes, launchFailureTypes, Flight } from '../../models/Flight';

type FreeFlightEntry = {
    date: string;
    type: string;
    paymentType: string;
    description: string;
    qty: string;
    price: string;
    nett: string;
    vat: string;
    total: string;
    qbName: string;
};

function parseDate(dateString: string): Date {
    const dateParts = dateString.split('/');
    if (dateParts.length !== 3) {
        throw new Error(`Invalid date format: ${dateString}`);
    }
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript
    const year = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
}

export default function FlightImport() {
    const { gliders, selectableUsers, updateUnpublishedUserFlyingDays } = useDisplayedListsContext();
    const { addFlight, updateUserFreeFlightID, getAllFlightsDateRange, getTotalUserList } = useAdminContext();
    const [error, setError] = useState<string>("");
    const [freeFlightIDsToFind, setFreeFlightIDsToFind] = useState<Array<{ id: string, resolve: () => void }>>([]);
    const [selectedUser, setSelectedUser] = useState<SelectableUser>();
    const [selectableUsersWithoutFreeflightIDs, setSelectableUsersWithoutFreeflightIDs] = useState<Array<SelectableUser>>(selectableUsers);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const file = useRef<HTMLInputElement>(null);

    async function refreshUsersWithoutFreeFlightIDs() {
        const userList = await getTotalUserList();
        const filteredUsers = selectableUsers.filter(user => {
            const userData = userList.find(u => u.uid === user.uid);
            return userData && !userData.freeFlightID;
        });
        setSelectableUsersWithoutFreeflightIDs(filteredUsers);
    }

    async function processCSV(file: File): Promise<FreeFlightEntry[]> {
        return new Promise((resolve, reject) => {
            if (!file) {
                setError("Invalid file");
                return reject("Invalid file");
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const lines = (e.target?.result as string).split('\n');
                if (lines[0].trim() !== 'Date,Type,Payment Type,Description,Qty,Price,Nett,VAT,Total,QB Name') {
                    console.error(`Is '${lines[0]}' but must be 'Date,Type,Payment Type,Description,Qty,Price,Nett,VAT,Total,QB Name'`);
                    setError("Must be a FreeFlight CSV");
                    return reject("Must be a FreeFlight CSV");
                }

                // get the data from the file
                const data = lines.slice(1).map((line) => {
                    const parts = line.split(',');
                    return {
                        date: parts[0],
                        type: parts[1],
                        paymentType: parts[2],
                        description: parts[3],
                        qty: parts[4],
                        price: parts[5],
                        nett: parts[6],
                        vat: parts[7],
                        total: parts[8],
                        qbName: parts[9],
                    };
                });

                resolve(data);
            };

            reader.onerror = (error) => {
                setError("Error reading file");
                reject(error);
            };

            reader.readAsText(file);
        });
    }

    async function ensureUserIDs(userFreeFlightIDs: Array<string>): Promise<void> {
        const existingUserList = await getTotalUserList();

        // Find the set of IDs which do not have a user associated with them
        let remainingIDs: Array<string> = [];
        userFreeFlightIDs.forEach((id) => {
            let user = existingUserList.find((user) => user.freeFlightID === id);
            // add to the list of IDs to find if no user is found
            if (!user) {
                remainingIDs.push(id);
            }
        });

        // This can be merged with the above for loop
        let newFreeFlightIDsToFindSet: Array<{ id: string, resolve: () => void }> = []
        let promises: Array<Promise<void>> = []
        remainingIDs.forEach((id) => {
            // May be able to change 
            let resolveFunction = () => { };
            let promise: Promise<void> = new Promise((res, reg) => { resolveFunction = res });
            newFreeFlightIDsToFindSet.push({ id: id, resolve: resolveFunction })
            promises.push(promise);
        });

        // Hand over control to the model, and wait for the modal to be finished
        setFreeFlightIDsToFind(newFreeFlightIDsToFindSet);
        refreshUsersWithoutFreeFlightIDs();
        await Promise.all(promises);
    }

    function fixedFreeFlightFlightIDFromChargeDescription(description: string): string
    /* the flight IDs from freeflight are fucked because they aren't unique
     so, we now prepend the user ID to the fucked flight ID 
     old freeflight IDS for flights before December 2024 on firebase */
    {
        return description.split(' ')[description.split(' ').length - 1] + '-' + description.split(' ')[5]
    }

    async function flightsFromFreeFlightCSV(data: FreeFlightEntry[]): Promise<Flight[]> {
        const charges = data.filter(
            (charge) => 
                   charge.type === 'Charge' 
                && charge.description.includes('Flight number')
        );
        const uniqueFlightNumbers = charges
            .map(
                (charge) => fixedFreeFlightFlightIDFromChargeDescription(charge.description)
            ).filter(
                (value, index, self) => self.indexOf(value) === index
            );
        const uniqueUserIDs = charges
            .map(
                (charge) => charge.description.split(' ')[charge.description.split(' ').length - 1]
            ).filter(
                (value, index, self) => self.indexOf(value) === index
            );

        await ensureUserIDs(uniqueUserIDs);
        const userList = await getTotalUserList();

        // Extract date, flight number, aircraft, member, minutes, launch fee, and minute fee from each charge
        const flights: Flight[] = [];
        for (const flightNumber of uniqueFlightNumbers) {
            // required by Flight type, but not inferrable from a freeflight charge
            const launchType: launchTypes = launchTypes.unknown;
            const launchFailure: launchFailureTypes = launchFailureTypes.unknown;
            const launchLocation: string = 'Shalbourne';

            // for each piece of data in flight, create a placeholder variable to start with
            const freeFlightID: string = flightNumber;
            let date: Date = new Date();
            let length: number = 0;
            let compNumber: string = '';
            let p1: SelectableUser = {} as SelectableUser;
            let costOverride: number = 0.00;

            // Format will be either one of the following:
            // Launch Fee for Flight number YYYY-AA in Aircraft G-XXXX for member XAA
            // Airtime Fee for Flight number YYYY-AA in Aircraft G-XXXX Duration H hrs M mins for member XAA
            const flightCharges = charges.filter((charge) => flightNumber === fixedFreeFlightFlightIDFromChargeDescription(charge.description));

            for (const charge of flightCharges) {
                const description = charge.description.split(' ');
                costOverride += -parseFloat(charge.price);
                date = parseDate(charge.date);
                compNumber = await compNumberFromReg(description[8]);
                let p1FreeFlightID = description[description.length - 1];

                try {
                    let foundUser = userList.find((current) => {
                        return (current.freeFlightID === p1FreeFlightID);
                    });
                    p1 = {
                        uid: foundUser!.uid,
                        firstName: foundUser!.firstName,
                        lastName: foundUser!.lastName,
                        age: foundUser!.dob
                            ?
                            Math.abs((new Date(Date.now() - foundUser!.dob.getTime())).getUTCFullYear() - 1970)
                            :
                            50,
                    }
                } catch (error) {
                    console.error(`Failed to select user for p1FreeFlightID: ${p1FreeFlightID}`, error);
                }

                const chargeType = description[0];
                //case on chargeType
                switch (chargeType) {
                    case 'Launch':
                        break;
                    case 'Airtime':
                        const hours = parseInt(description[10]);
                        const minutes = parseInt(description[12]);
                        length = hours * 60 + minutes;
                        break;
                    default:
                        console.error(`Unknown charge type: ${chargeType}`);
                        break;
                }
            }

            costOverride = Math.round(costOverride * 100);

            flights.push({
                date: date,
                length: length,
                compNumber: compNumber,
                launchType: launchType,
                launchFailure: launchFailure,
                launchLocation: launchLocation,
                p1: p1!,
                freeLaunch: false, // required boolean
                costOverride: costOverride,
                freeFlightId: freeFlightID,
            });
        };
        return flights;
    }

    async function compNumberFromReg(reg: string): Promise<string> {
        const glider = gliders.find((glider) => glider.reg === reg);
        if (!glider) {
            return Promise.reject(`Glider with registration ${reg} not found`);
        }
        return glider.compNumber;
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setError("");
        setButtonDisabled(true);

        // use processCSV to get the data from the file
        if (!file.current || !file.current.files || file.current.files.length === 0) {
            return setError("Invalid file");
        }

        try {
            const data = await processCSV(file.current.files[0]);
            try {
                let flights = await flightsFromFreeFlightCSV(data);

                // Determine the date range of the imported data
                const startDate = new Date(Math.min(...flights.map(flight => flight.date.getTime())));
                const endDate = new Date(Math.max(...flights.map(flight => flight.date.getTime())));
                
                const existingFlights = await getAllFlightsDateRange(startDate, endDate);
                if (existingFlights.length > 0) {
                    // filter flights to only include flights after the last existing flight
                    let maxDate = Math.max(...existingFlights.map(flight => flight.date.getTime()));
                    const totalFlightsRequestedForImport = flights.length;
                    flights = flights.filter(flight => flight.date.getTime() > maxDate);
                    const flightsDropped = totalFlightsRequestedForImport - flights.length;

                    setError(`PLEASE REVIEW MANUALLY BEFORE PUBLISHING - Flights already exist within the date range. Only flights after ${new Date(maxDate).toLocaleDateString("en-GB", { day: 'numeric', month: 'long', year: 'numeric' })} will be imported. ${flightsDropped}/${totalFlightsRequestedForImport} flights dropped.`);
                }


                // add to unpublished flights
                for (const flight of flights) {
                    await addFlight(
                        flight.date,
                        flight.length,
                        flight.compNumber,
                        flight.launchType,
                        flight.launchFailure,
                        flight.launchLocation,
                        flight.p1,
                        flight.p2,
                        flight.freeLaunch,
                        flight.costOverride,
                        flight.freeFlightId
                    );
                }
                await updateUnpublishedUserFlyingDays();
                setButtonDisabled(false);

                // clear file input
                if (file.current) {
                    file.current.value = "";
                }
            } catch (error) {
                console.error(error);
                return;
            }
        } catch (error) {
            console.error(error);
            setError("Error processing CSV file");
            return;
        }
    }

    async function onSubmitModal(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!selectedUser) {
            setError("User not selected");
            return;
        }

        let tmp = freeFlightIDsToFind[0]
        await updateUserFreeFlightID(selectedUser.uid, tmp.id);
        refreshUsersWithoutFreeFlightIDs();
        setFreeFlightIDsToFind((current) => {
            let removed_val = current.shift();
            if (removed_val !== tmp) throw console.error(`Removed unexpected val`);
            return current;
        });
        tmp.resolve();
        setSelectedUser(undefined);
    }

    return (
        <Card>
            <Card.Header>
                <h2>Import Flights</h2>
            </Card.Header>
            <Card.Body className="d-flex justify-content-center">
                <Form onSubmit={onSubmit}>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form.Group id="date">
                        <Form.Label className='mb-0'>File:</Form.Label>
                        <Form.Control
                            type='file'
                            accept='.csv'
                            ref={file}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button className='w-100 mt-3' type='submit' disabled={buttonDisabled}>Inport</Button>
                    </Form.Group>
                </Form>
                <Modal show={freeFlightIDsToFind.length !== 0}>
                    <Form onSubmit={onSubmitModal}>
                        <Modal.Header>
                            <Modal.Title>Link FreeFlight ID</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Which user has the FreeFlight ID of <b>{freeFlightIDsToFind[0] === undefined ? "Shit coding blame CG" : freeFlightIDsToFind[0].id}</b>?</Form.Label>
                                <Typeahead
                                    id="UserToAssignFreeFlightIdSelector"
                                    onChange={(selected: Array<SelectableUser>) => {
                                        setSelectedUser(selected.pop());
                                    }}
                                    selected={selectedUser ? [selectedUser] : []}
                                    labelKey={(option: SelectableUser) => `${option.firstName} ${option.lastName}`}
                                    options={selectableUsersWithoutFreeflightIDs}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='primary' type='submit' disabled={selectedUser === undefined}>Confirm</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Card.Body>
        </Card>
    )
}
