import * as React from 'react';
import { useRef } from "react";
import { Alert, Button, Card, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FlyingRate } from '../../models/FlyingRate';
import { useAdminContext } from '../../contexts/AdminContext';

export default function FlyingRateGeneration() {
  const { addFlyingRate } = useAdminContext();

  const rateName = useRef<HTMLInputElement>(null);
  
  const actualLaunchCost = useRef<HTMLInputElement>(null);
  const actualMinuteCost = useRef<HTMLInputElement>(null);
  const actualTLFCost = useRef<HTMLInputElement>(null);
  const subsidisedLaunchCost = useRef<HTMLInputElement>(null);
  const subsidisedMinuteCost = useRef<HTMLInputElement>(null);
  const subsidisedTLFCost = useRef<HTMLInputElement>(null);

  const subsidisedAmountOfMinutes = useRef<HTMLInputElement>(null);
  const dailyRentalCost = useRef<HTMLInputElement>(null);
  const maxAge = useRef<HTMLInputElement>(null);

  const periodStart = useRef<HTMLInputElement>(null);
  const periodEnd = useRef<HTMLInputElement>(null);
  
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (
      !rateName.current ||
      !actualLaunchCost.current ||
		  !actualMinuteCost.current ||
		  !actualTLFCost.current ||
		  !subsidisedLaunchCost.current ||
		  !subsidisedMinuteCost.current ||
		  !subsidisedTLFCost.current ||
      !subsidisedAmountOfMinutes.current ||
		  !dailyRentalCost.current ||
		  !maxAge.current ||
      !periodStart.current ||
		  !periodEnd.current 
    ) return;

    if (periodStart.current.valueAsDate === null) return;


    let periodEndValue = new Date();
    periodEndValue.setDate(periodEndValue.getDate() + (365 * 10));
    
    if (periodEnd.current.valueAsDate !== null) 
      periodEndValue = periodEnd.current.valueAsDate;

    addFlyingRate(new FlyingRate(
      rateName.current.value,
      actualLaunchCost.current.valueAsNumber,
		  actualMinuteCost.current.valueAsNumber,
		  actualTLFCost.current.valueAsNumber,
		  subsidisedLaunchCost.current.valueAsNumber,
		  subsidisedMinuteCost.current.valueAsNumber,
		  subsidisedTLFCost.current.valueAsNumber,
      subsidisedAmountOfMinutes.current.valueAsNumber,
		  dailyRentalCost.current.valueAsNumber,
		  maxAge.current.valueAsNumber,
      periodStart.current.valueAsDate,
      periodEndValue
    ))

    
  }

  const nameTooltip = (
    <Tooltip id="tooltip">
      Options are <kbd>default</kbd>, <kbd>defaultJunior</kbd>, <kbd>gliderReg</kbd> or <kbd>gliderRegJunior</kbd> with <kbd>gliderReg</kbd> referencing comp number.
    </Tooltip>
  );

  return (
    <Card>
      <Card.Header>
        <h2>Add A Flight Rate</h2>
        <Alert variant='danger'>
          This has ZERO input protection - don't use it unless you have access to firebase to finalize the result and have been shown how to.
        </Alert>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center">
        <Form onSubmit={onSubmit}>
          <Form.Group id="rateName">
            <Form.Label>Rate Name:</Form.Label>
            <OverlayTrigger placement='right' overlay={nameTooltip}>
              <Form.Control
                type='text'
                ref={rateName}
                required
              />
            </OverlayTrigger>
          </Form.Group>
          <Form.Group id="actualLaunchCost">
            <Form.Label>Actual Launch Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={actualLaunchCost}
                required
                aria-describedby='actualLaunchCostPence'
              />
              <InputGroup.Text id="actualLaunchCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="actualMinuteCost">
            <Form.Label>Actual Minute Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={actualMinuteCost}
                required
                aria-describedby='actualMinuteCostPence'
              />
              <InputGroup.Text id="actualMinuteCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="actualTLFCost">
            <Form.Label>Actual TLF Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={actualTLFCost}
                required
                aria-describedby='actualTLFCostPence'
              />
              <InputGroup.Text id="actualTLFCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="subsidisedLaunchCost">
            <Form.Label>Subsidised Launch Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={subsidisedLaunchCost}
                required
                aria-describedby='subsidisedLaunchCostPence'
              />
              <InputGroup.Text id="subsidisedLaunchCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="subsidisedMinuteCost">
            <Form.Label>Subsidised Minute Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={subsidisedMinuteCost}
                required
                aria-describedby='subsidisedMinuteCostPence'
              />
              <InputGroup.Text id="subsidisedMinuteCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="subsidisedTLFCost">
            <Form.Label>Subsidised TLF Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={subsidisedTLFCost}
                required
                aria-describedby='subsidisedTLFCostPence'
              />
              <InputGroup.Text id="subsidisedTLFCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="subsidisedAmountOfMinutes">
            <Form.Label>Subsidised Amount Of Minutes:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={subsidisedAmountOfMinutes}
                required
                aria-describedby='subsidisedAmountOfMinutesMins'
              />
              <InputGroup.Text id="subsidisedAmountOfMinutesMins">mins</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="dailyRentalCost">
            <Form.Label>Daily Rental Cost:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={dailyRentalCost}
                required
                aria-describedby='dailyRentalCostPence'
              />
              <InputGroup.Text id="dailyRentalCostPence">p</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="maxAge">
            <Form.Label>Max Age:</Form.Label>
            <InputGroup>
              <Form.Control
                type='number'
                ref={maxAge}
                required
                aria-describedby='maxAgeYears'
              />
              <InputGroup.Text id="maxAgeYears">Years</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="periodStart">
            <Form.Label>Period Start:</Form.Label>
            <Form.Control
              type='date'
              ref={periodStart}
              required
            />
          </Form.Group>
         <Form.Group id="periodEnd">
            <Form.Label>Period End (optional):</Form.Label>
            <Form.Control
              type='date'
              ref={periodEnd}
            />
          </Form.Group>
          <Form.Group>
            <Button className='w-100 mt-3' type='submit'>Submit</Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
